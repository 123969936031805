import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import FullPageLoader from 'libs/loader/FullPageLoader'
import NewEditableTable from 'libs/table/NewEditableTable'
import { useGetMachineQuery } from 'pages/maintenance/machines/machine-endpoints/machine.endpoints'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'

const machineColumns = [
  {
    title: 'Machine',
    accessor: 'machine',
    required: true,
    flex: 2,
    main: true,
    editable: true,
    placeholder: 'Select Machine Order',
  },
  {
    title: 'Machine Class',
    accessor: 'machineClass',
  },
  {
    title: 'Model Number',
    accessor: 'modelNumber',
  },
  {
    title: 'Serial Number',
    accessor: 'serialNumber',
  },
]

const defaultFilters = {
  page_no: 1,
  page_size: 1000,
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const MachineDetails = ({ formik, setPage }: any) => {
  const [data, setData] = useState<any[]>()
  const { id } = useParams()
  const [emptyMachine, setEmptyMachine] = useState(false)
  const plantId = useSelector(selectSelectedItem)
  const navigate = useNavigate()
  const { data: machineData, isLoading: isMachineDataLoading } = useGetMachineQuery(
    {
      plantId: plantId?.id,
      filters: defaultFilters,
    },
    {
      skip: !plantId?.id,
    },
  )

  useEffect(() => {
    setPage(2)
  }, [])

  const machineFormik = useFormik<any>({
    initialValues: {
      machines: [],
    },
    onSubmit: () => {
      if(data == null || data == undefined) {
        setEmptyMachine(true)
      }else{
        formik.setFieldValue('machines', data)
        if (id) {
          navigate(`/settings/configure/work-centers/${id}/edit/shift-details`)
        } else {
          navigate('/settings/configure/work-centers/add/shift-details')
        }
      }
    },
  })

  useEffect(()=>{
    if( data != null ){
      setEmptyMachine(false)
    }
  },[data])

  useEffect(() => {
    const d = formik.values.machines
    if (d != null && d.length > 0) {
      setData(d)
    }
  }, [formik.values.machines])

  useEffect(() => {
    machineFormik.setFieldValue('machines', data)
  }, [data])

  const machineOptions = useMemo(
    () =>
      machineData?.data?.map((item: any) => ({
        id: item?._id,
        label: item?.name,
        value: {
          _id: item?._id,
          serialNumber: item?.serialNumber,
          machineClass: item?.machineClass?.name,
          modelNumber: item?.machineClass?.models?.modelNumber,
        },
      })),
    [machineData?.data],
  )

  return (
    <>
      {isMachineDataLoading ? (
        <div className="relative mx-auto flex items-center">
          <FullPageLoader />
        </div>
      ) : (
        <div className="flex min-h-[70vh] w-full flex-col px-2">
          <div>
            <div className='flex'>
            <h5 className="h5-1">{'Machine Details'}</h5>
            <span data-testid="asterisk" className={'text-red'}>
                *
              </span>
            </div>
            <p className="text-primary-gray-4 text-sm">
              {id
                ? 'Edit the existing machine details to make necessary changes and updates.'
                : 'Add machine details to assign equipment to the work center.'}
            </p>
          </div>
          <div className="py-6">
            <NewEditableTable
              defaultRows={formik?.values?.machines}
              columns={machineColumns}
              options={machineOptions}
              uniqueIdInOptions="_id"
              errorType={emptyMachine}
              errorMessage='Error: Machine details are missing for adding new work center. Please select Machine details to proceed.'
              addButtonTitle="Add Machine"
              onChange={(values: any) => {
                setData(values)
              }}
            />
          </div>
          <div className="mt-auto flex justify-between gap-2">
            <div className="flex gap-3">
              <Button type="button" onClick={() => navigate('/settings/configure/work-centers')}>
                Cancel
              </Button>
            </div>

            <div className="flex gap-3">
                <Button
                  type="submit"
                  onClick={() => {
                    if (id) {
                      navigate(`/settings/configure/work-centers/${id}/edit/basic-details`);
                    } else {
                      navigate('/settings/configure/work-centers/add/basic-details');
                    }
                  }}
                >
                  Previous
                </Button>
              <Button
                color="success"
                type="submit"
                onClick={() => {
                  machineFormik.handleSubmit()
                }}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MachineDetails
