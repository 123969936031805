import { useEffect, useState } from 'react'
import BlueTick from 'assets/icons/product/BlueTick'
import { useNavigate, Route, Routes, useParams, To } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { scaleMotionProps2 } from 'utils/FramerProps'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice.js'
import useTabTitle from 'libs/hooks/useTabTitle.ts'
import {
  
  useGetWorkCenterByIdQuery,
  usePostWorkCenterMutation,
  usePutWorkCenterMutation,
} from '../work-center-endpoints/workcenter.endpoint'
import { toast } from 'libs/toast'
import BasicDetails from './Tabs/BasicDetails'
import MachineDetails from './Tabs/MachineDetails'
import ShiftDetails from './Tabs/ShiftDetails'


const steps = [
  { label: 'Basic Details', path: 'basic-details' },
  { label: 'Machine Details', path: 'machine-details' },
  { label: 'Shift Details', path: 'shift-details' },
]

const AddEditWorkCenterWizard = () => {
  const plantId = useSelector(selectSelectedItem)
  const { id } = useParams()
  const [addWorkCenter, { isLoading: addWorkCenterLoading }] = usePostWorkCenterMutation()
  const [editWorkCenter, { isLoading: editWorkCenterLoading }] = usePutWorkCenterMutation()
  const { data: workCenterData } = useGetWorkCenterByIdQuery(
    {
      wcID: id,
      plantId: plantId?.id,
    },
    {
      skip: !id || !plantId?.id,
    },
  )

  const navigate = useNavigate()
  const [page, setPage] = useState<number>(1)

  useTabTitle(id ? `Update Work Center` : 'Add Work Center')

  const workCenterFormik = useFormik<any>({
    initialValues: {
      workCenterID: '',
      name: '',
      operations: [],
      description: '',
      machines: [],
      shifts: [],
    },
    validationSchema: Yup.object().shape({
      workCenterID: Yup.string().required('Work Center ID is required'),
      name: Yup.string().required('Work Center Name is required'),
    }),
    onSubmit: (values) => {
      const payload = {
        workCenterID: values.workCenterID,
        name: values.name,
        description: values.description,
        operations: values?.operations?.length > 0 ? values?.operations : [],
        machines:
          values?.machines?.length > 0 ? values.machines.map((item: any) => item?.machine?._id)?.filter(Boolean) : [],
        shifts:
          values?.shifts?.length > 0
            ? values.shifts.map((shiftItem: any) => ({
                shiftPolicyID: shiftItem?.shiftPolicy?._id || null,
                shiftIDs: shiftItem?.shift?.map((shiftDetail: any) => shiftDetail?.id).filter((id: any) => id), // Filter out any null ids
              }))
            : [],
      }
      if (!id) {
        addWorkCenter({
          plantId: plantId?.id,
          data: payload,
        })
          .unwrap()
          .then(() => {
            toast.success(
              <div>
                Work Center <b>"{payload?.workCenterID}"</b> is created successfully.
              </div>,
            )
            navigate('/settings/configure/work-centers')
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong. Please try again.')
          })
      } else if (id) {
        editWorkCenter({
          plantId: plantId?.id,
          wcID: id,
          data: payload,
        })
          .unwrap()
          .then(() => {
            toast.success(
              <div>
                Work Center <b>"{payload?.workCenterID}"</b> is updated successfully.
              </div>,
            )
            navigate('/settings/configure/work-centers')
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong. Please try again.')
            workCenterFormik.setFieldValue('isDraft', false)
          })
      }
    },
  })

  

  useEffect(() => {
    if (workCenterData) {
      workCenterFormik.resetForm({
        values: {
          workCenterID: workCenterData?.workCenterID,
          name: workCenterData?.name,
          description: workCenterData?.description,
          operations: workCenterData?.operations?.map((item:any) => item?._id),
          machines: workCenterData?.machines?.map((item: any) => ({
            machine: { name: item?.name, _id: item?._id },
            machineClass: item?.machineClass?.name,
            modelNumber: item?.machineClass?.models?.modelNumber,
            serialNumber: item?.serialNumber,
          })),
          shifts: workCenterData?.shiftPolicies?.map((shift: any) => ({
            shiftPolicy: {name: shift?.policyName, _id: shift?._id},
            shift: shift?.shifts?.map((shiftDetail: any) => ({
              name: shiftDetail?.shiftName,
              id: shiftDetail?.id
            })),
          }))
        },
      })
    }
  }, [workCenterData])
  
  const handleNavigation = (stepIndex: number, path: To) => {
    if (id) {
      setPage(stepIndex + 1)
      navigate(path)
    }
  }

  return (
    <AnimatePresence>
      <motion.div {...scaleMotionProps2}>
        <div className="flex justify-between">
          <div className="flex items-baseline gap-2 ">
            <h2 className="mb-2 max-w-[500px] truncate h4-bold-black">{id ? 'Edit Work Center' : 'Add Work Center'}</h2>
          </div>
          <div className="mt-4 flex items-baseline gap-1 p-md-regular">
            <div>{page === 2 ? 'Last updated :' : null}</div>
            <div>{page === 2 ? 'Just now' : null}</div>
          </div>
        </div>
        <div className="flex min-h-[65vh] w-full rounded  bg-[#FFFFFF] p-6">
          <div className="relative w-1/4 border-r border-[#D2DADF] p-2">
            {steps.map((step, index) => (
              <div key={index}>
                <button onClick={() => handleNavigation(index, step.path)} className="flex items-center">
                  <div
                    className={`h-6 w-6 rounded-3xl border p-2 ${
                      page === index + 1 ? 'border-primary ' : 'border-[#B9C4CC]'
                    } ${
                      page > index + 1 ? 'border-primary bg-primary' : 'border-[#B9C4CC]'
                    } flex items-center justify-center`}
                  >
                    <div className={`text-xs font-bold  `}>{page > index + 1 ? <BlueTick /> : index + 1}</div>
                  </div>
                  <p
                    className={`whitespace-nowrap rounded px-2 text-sm ${page == index + 1 ? 'font-bold' : ''} ${!id ? ' cursor-not-allowed' : ''}`}
                  >
                    {step.label}
                  </p>
                </button>
                {index < steps.length - 1 && (
                  <span
                    className={`ml-3 flex h-[16px] border-l-2 border-dashed ${
                      page > index + 1 ? 'border-primary' : 'border-[#B9C4CC]'
                    }`}
                  ></span>
                )}
              </div>
            ))}
          </div>
          <div className="ml-2  flex w-full " style={{ overflowX: 'auto' }}>
            <Routes>
              <Route path="basic-details" element={<BasicDetails setPage={setPage} formik={workCenterFormik} />} />
              <Route path="machine-details" element={<MachineDetails setPage={setPage} formik={workCenterFormik} />} />
              <Route
                path="shift-details"
                element={
                  <ShiftDetails
                    setPage={setPage}
                    loading={editWorkCenterLoading || addWorkCenterLoading}
                    formik={workCenterFormik}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default AddEditWorkCenterWizard
