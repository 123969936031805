import Button from 'libs/button/Button'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useGetMachineByIDQuery } from '../../machine-endpoints/machine.endpoints'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { formatUnixDateMultipleWithTimeZone } from 'utils/unixConverter'

const ViewPartsDetails = () => {
  const plant = useSelector(selectSelectedItem)
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: machineData, isLoading: isMachineDataLoading } = useGetMachineByIDQuery(
    {
      plantId: plant?.id,
      machineID: params?.id,
    },
    {
      skip: !plant?.id || !params?.id,
    },
  )

  const column = [
    {
      Header: 'Part Name',
      accessor: 'name',
      width: 100,
      Cell: ({ row }: any) => {
        const data = row?.original
        return <div>{data?.name ?? '-'}</div>
      },
      disableSortBy: true,
    },
    {
      Header: <div className="flex w-full items-center justify-end">Serial Number</div>,
      accessor: 'serialNumber',
      width: 100,
      Cell: ({ row }: any) => {
        const data = row?.original
        return <div>{data?.serialNumber ?? '-'}</div>
      },
      disableSortBy: true,
    },
    {
      Header: <div className="flex w-full items-center justify-end">Manufacturer</div>,
      accessor: 'description',
      width: 100,
      Cell: ({ row }: any) => {
        const data = row?.original
        return <div>{data?.manufacturer ?? '-'}</div>
      },
      disableSortBy: true,
    },
    {
      Header: <div className="flex w-full items-center justify-end">Last Replaced</div>,
      accessor: 'machines',
      width: 100,
      Cell: ({ row }: any) => {
        const data = row?.original
        return <div>{formatUnixDateMultipleWithTimeZone(data?.lastReplaced, plant?.timezone) ?? '-'}</div>
      },
      disableSortBy: true,
    },
  ]

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="items-center text-xl font-medium text-brand-primary-shade-3">Parts Details</h1>
        <Button color="primary" onClick={()=> navigate(`/maintenance/machines/${machineData?._id}/edit/parts-details`, {state: {from: location}})}>Manage Parts</Button>
      </div>
      <div className="py-3">
        <PaginatedTable
          columns={column}
          rows={machineData?.partsDetails}
          loading={isMachineDataLoading}
          hidePagination
          emptyMessage="No parts details have been added. Please add the parts details."
        />
      </div>
    </>
  )
}

export default ViewPartsDetails
