export const DateTimeConverter = (otp_updated_time: number) => {
  const currTime = new Date()
  return Math.floor(currTime.getTime() / 1000) - otp_updated_time
}

export const dateToUnix = (date: any) => {
  return date ? Math.floor(date?.getTime() / 1000) : null
}

export const calculateTimeWithSeconds = (seconds: number): string => {
  // Calculate total hours and minutes from seconds
  const totalHours = Math.floor(seconds / 3600)
  const totalMinutes = Math.floor((seconds % 3600) / 60)

  // Determine the period (AM/PM)
  const period = totalHours >= 12 ? 'PM' : 'AM'

  // Convert to 12-hour format
  const formattedHours = totalHours % 12 === 0 ? 12 : totalHours % 12

  // Pad minutes with leading zero if needed
  const formattedMinutes = totalMinutes.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes} ${period}`
}

export const calculateDuration = (startTime: number, endTime: number) => {
  // Calculate duration in seconds
  const durationSeconds = endTime - startTime

  // If the duration is negative, it means the time crosses midnight
  const adjustedDurationSeconds = durationSeconds < 0 ? durationSeconds + 24 * 3600 : durationSeconds

  // Calculate hours and minutes from the adjusted duration
  const hours = Math.floor(adjustedDurationSeconds / 3600)
  const minutes = Math.floor((adjustedDurationSeconds % 3600) / 60)

  return `${hours}h ${minutes}m`
}


// Example usage:
