import { api } from 'common/config'
import { getToken } from 'utils/CheckAuth'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axios from 'axios'
import { getAuthTokenAndRole } from 'utils/CheckAuth'
import { toastFailure } from 'libs/toast/toastFailure'
import { toastSuccess } from 'libs/toast/toastSucess'

// Define your API service
export const rootReducer = createApi({
  reducerPath: 'catalyst',
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  tagTypes: [
    'GetCustomers',
    'GetWorkOrders',
    'GetEmployeeCount',
    'PlantsUpdate',
    'UserInfo',
    'inventoryUpdate',
    'INVENTORY_TRANSACTIONS',
    'updateProcess',
    'updateProcessProduct',
    'updateProcessMetric',
    'updateProcessEntry',
    'productUpdate',
    'getProperty',
    'bomUpdate',
    'productionplanUpdate',
    'getproductionplan',
    'CameraUpdate',
    'ContainerUpdate',
    'DepartmentUpdate',
    'ProductionUpdate',
    'getProduction',
    'updateDashboard',
    'warehouseUpdate',
    'warehouseDelete',
    'GetManufacturerOrder',
    'salesOrderUpdate',
    'purchaseOrderUpdate',
    'employeeUpdate',
    'vendorUpdate',
    'EmployeeDepartmentUpdate',
    'getSingleDepartment',
    'getSingleVendor',
    'approveEntries',
    'getShiftPolicy',
    'updateShiftPolicy',
    'getSingleWorkOrder',
    'timesheetUpdate',
    'RolesInfo',
    'getMaintenanceRequests',
    'GetMaintenanceRequestsCount',
    'maintenanceOrderUpdate',
    'getMaintenanceOrder',
    'getMaintenanceOrderById',
    'getMaintenanceOrderCount',
    'getMaintenanceRequestById',
    'getMachineClasses',
    'getMachineClassById',
    'getMachine',
    'getWorkCenters',
    'getWorkCenterById',
    'getMachineByID',
  ],
  endpoints: () => ({}),
})

// File download function
export const sampleFileDownload = async () => {
  try {
    const response = await axios.get(`${api}/plants/download_template`, {
      responseType: 'arraybuffer',
      headers: getToken(),
    })
    const filename = 'plant_template.xlsx'
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error downloading file:', error)
    toastFailure('Failed to download file.')
  }
}

// File upload function
export const uploadFile = async (file: File) => {
  const { access_token } = getAuthTokenAndRole()
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + access_token,
      },
    }

    const response = await axios.post(`${api}/plants/using_import`, file, config)

    if (response.status === 200) {
      toastSuccess('File uploaded successfully')
    } else {
      toastFailure('File upload failed. Please check if you are uploading the right sample.')
    }
  } catch (error: any) {
    toastFailure('File upload failed. Please check if you are uploading the right sample.')
    throw new Error(error.message)
  }
}
