import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/BuildUrl'
import {
  WorkCentersResponse,
  GetWorkCenterParams,
  DeleteWorkCenterRequest,
  DeleteWorkCenterResponse,
  GetByWorkCenterResponse,
  GetByIdWorkCenterRequest,
} from './workcenterTypes'

export const workCenters = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getWorkCenters: builder.query<WorkCentersResponse, GetWorkCenterParams>({
      query: (params) => {
        const baseURL = `/plant/${params.plantId}/work-centers`
        const URL = params.filters ? buildUrl(baseURL, params.filters) : baseURL
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the work centers details.')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getWorkCenters'],
    }),

    deleteWorkCenter: builder.mutation<DeleteWorkCenterResponse, DeleteWorkCenterRequest>({
      query: (params) => {
        return {
          url: `/plant/${params.plantId}/work-center/${params.wcID}`,
          method: 'DELETE',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to delete the machine')
            }
            return response.json()
          },
        }
      },
      invalidatesTags: ['getWorkCenters'],
    }),
    getWorkCenterById: builder.query<GetByWorkCenterResponse, GetByIdWorkCenterRequest>({
      query: ({ plantId, wcID }) => {
        return {
          url: `/plant/${plantId}/work-center/${wcID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Work center by id')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getWorkCenterById'],
    }),

    getWorkCenterCount: builder.query({
      query: ({ plantId }) => {
        return {
          url: `/plant/${plantId}/work-centers-count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the work centers count')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getWorkCenters'],
    }),
    postWorkCenter: builder.mutation({
      query: ({ plantId, data }) => {
        return {
          url: `/plant/${plantId}/work-center`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['getWorkCenters'],
    }),
    putWorkCenter: builder.mutation({
      query: ({ plantId, data, wcID }) => {
        return {
          url: `/plant/${plantId}/work-center/${wcID}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['getWorkCenters'],
    }),
  }),
  overrideExisting: false,
})

export const { useGetWorkCentersQuery, useDeleteWorkCenterMutation, useGetWorkCenterByIdQuery,useGetWorkCenterCountQuery , usePostWorkCenterMutation, usePutWorkCenterMutation} = workCenters
