import { useSelector } from "react-redux"
import { selectSelectedItem } from "store/redux/navSlice"

const userLocale = navigator.language
const userTimeZone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone
// const plantId = useSelector(selectSelectedItem)

export const formatUnixTime = (unixTimestamp: number | undefined) => {
  if (!unixTimestamp) return '-'
  const date = new Date(unixTimestamp * 1000)
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: userTimeZone,
  }
  const formattedTime = new Intl.DateTimeFormat(userLocale, options).format(date)
  return formattedTime.toUpperCase()
}
export const formatUnixDate = (unixTimestamp: number | undefined) => {
  if (!unixTimestamp) return '-'
  const date = new Date(unixTimestamp * 1000)
  if (isNaN(date.getTime())) return ''
  const formattedDate = new Intl.DateTimeFormat(userLocale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: userTimeZone,
  }).format(date)
  return formattedDate.replace(/\//g, '-').toUpperCase().replace(',', '')
}

export const formatUnixDateWithTime = (unixTimestamp: number | undefined, userTimeZone: string) => {
  if (!unixTimestamp) return '-'
  // Create a new Date object with the Unix timestamp
  const date = new Date(unixTimestamp * 1000)
  const formattedDate = date.toLocaleString(userLocale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: userTimeZone,
  })
  return formattedDate.replace(/\//g, '-').toUpperCase().replace(',', '')
}

export function formatDate(unixTimestamp: number | undefined) {
  if (!unixTimestamp) return '-'
  const date = new Date(unixTimestamp * 1000)
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: userTimeZone,
  }
  const formattedDate = new Intl.DateTimeFormat(userLocale, options).format(date)
  return formattedDate.replace(/\//g, '-')
}

export const getDaysRemaining = (startDate: number, endDate: number) => {
  const startDateObj = new Date(startDate * 1000)
  const endDateObj = new Date(endDate * 1000)
  const differenceMs = Math.abs(endDateObj.getTime() - startDateObj.getTime())
  const differenceDays = Math.ceil(differenceMs / (1000 * 3600 * 24))

  return differenceDays + 1
}

export const formatDateRange = (start: number, end: number): string => {
  return `${formatDateWithoutYear(start)} - ${formatDateWithoutYear(end)}`
}

export const formatDateWithoutYear = (unixTimestamp: any | string) => {
  if (!unixTimestamp) return '-'
  const date = new Date(unixTimestamp * 1000)
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    timeZone: userTimeZone,
  }
  const formattedDate = new Intl.DateTimeFormat(userLocale, options).format(date)
  return formattedDate
}

export const formatUnixDateVerbose = (unixTimestamp: number | undefined, userTimeZone: string) => {
  if (!unixTimestamp) return '-'
  const date = new Date(unixTimestamp * 1000)
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: userTimeZone,
  }
  const formattedDate = new Intl.DateTimeFormat(userLocale, options).format(date)
  return formattedDate
}

export const formatUnixDateMultiple = (unixTimestamp: number | undefined, userTimeZone: string) => {
  if (!unixTimestamp) return '-'
  const date = new Date(unixTimestamp * 1000)
  if (isNaN(date.getTime())) return ''
  const formattedDate = new Intl.DateTimeFormat(userLocale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: userTimeZone,
  }).format(date)
  return formattedDate.replace(/\//g, '/').toUpperCase().replace(',', '')
}

export const formatUnixDateMultipleWithTimeZone = (unixTimestamp: number, userTimeZone: string) => {
  if (unixTimestamp === null) return '-'
  const date = new Date(unixTimestamp * 1000)
  if (isNaN(date.getTime())) return ''
  const formattedDate = new Intl.DateTimeFormat(userLocale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: userTimeZone,
  }).format(date)
  return formattedDate.replace(/\//g, '/').toUpperCase().replace(',', '')
}

export const formatUnixTimeWithoutSecond = (unixTimestamp: number | undefined) => {
  if (!unixTimestamp) return '-'
  const date = new Date(unixTimestamp * 1000)
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: userTimeZone,
    hour12: true,
  }
  const formattedTime = new Intl.DateTimeFormat(userLocale, options).format(date)
  return formattedTime.toUpperCase()
}

export function formatTimestampToIST(unixTimestamp: number | undefined) {
  const plantId = useSelector(selectSelectedItem)
  // Create a Date object from the Unix timestamp (in milliseconds)
  if (!unixTimestamp) return '-'
  const date = new Date(unixTimestamp * 1000);

  // Define options for formatting the date and time
  const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',    // Day of the month
      month: 'short',    // Short month name (Jan, Feb, etc.)
      year: 'numeric',   // Full year (2024, etc.)
      hour: '2-digit',   // 2-digit hour
      minute: '2-digit', // 2-digit minute
      timeZone: plantId?.timezone, // IST timezone
      hour12: false,
  };

  // Format the date string
  
  const formattedDate = date.toLocaleString('en-GB', options);

  // Format and return the string in the desired format: "3 Jan, 2024 - 18:24 IST"
  return `${formattedDate.replace(',', ' -')} IST`;
}
