import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/BuildUrl'

import {
  GetMachineClassParams,
  PostMachineClassParams,
  PostMachineClassResponse,
  GetMachineClasses,
} from './machineClassTypes'

export const machineClass = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postMachineClass: builder.mutation<PostMachineClassResponse, PostMachineClassParams>({
      query: ({ data, plant_id }) => {
        return {
          url: `plant/${plant_id}/machineClass`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['getMachineClasses', 'getMachineClassById'],
    }),

    getMachineClasses: builder.query<GetMachineClasses, GetMachineClassParams>({
      query: (params) => {
        const baseURL = `/plant/${params.plantId}/machine-classes`
        const URL = params.filters ? buildUrl(baseURL, params.filters) : baseURL
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the machine classes details.')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getMachineClasses'],
    }),
    deleteMachineClass: builder.mutation({
      query: ({ plantId, machineClassID }) => ({
        url: `/plant/${plantId}/machine-class/${machineClassID}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['getMachineClasses', 'getMachine'],
    }),

    updateMachineClass: builder.mutation({
      query: ({ plant_id, machineClassID, data }) => {
        return {
          url: `/plant/${plant_id}/machineClass/${machineClassID}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['getMachineClasses', 'getMachineClassById'],
    }),
    getMachineClassById: builder.query({
      query: ({ plantId, machineClassID }) => {
        return {
          url: `/plant/${plantId}/machineClass/${machineClassID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Machine class by id')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getMachineClassById'],
    }),
    getMachineClassesCount: builder.query({
      query: ({plantId}) => {
        return {
          url: `/plant/${plantId}/machine-classes-count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the machine classes count')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getMachineClasses'],
    }),
  }),

  overrideExisting: false,
})

export const {
  useGetMachineClassesQuery,
  useDeleteMachineClassMutation,
  usePostMachineClassMutation,
  useGetMachineClassByIdQuery,
  useUpdateMachineClassMutation,
  useGetMachineClassesCountQuery
} = machineClass
