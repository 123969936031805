import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import ProductTab from 'pages/work-orders/components/ProductTab'
import { useState } from 'react'
import { useGetWorkCenterByIdQuery } from '../work-center-endpoints/workcenter.endpoint'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useNavigate, useParams } from 'react-router-dom'
import DeleteWorkCenter from '../popup/DeleteWorkCenter'
import Popup from 'libs/popup/Popup'
import FullPageLoader from 'libs/loader/FullPageLoader'
import Tags from 'libs/tags/Tags'
import Tooltip from 'libs/tool-tip/Tooltip'
import { formatUnixTimeWithoutSecond } from 'utils/unixConverter'
import useTabTitle from 'libs/hooks/useTabTitle'

const WorkCenterTab = [
  {
    _id: 1,
    name: 'Operations',
  },
  {
    _id: 2,
    name: 'Machine Details',
  },
  {
    _id: 2,
    name: 'Shift Details',
  },
]

const ViewWorkCenter = () => {
  useTabTitle('View Work Center')
  const navigate = useNavigate()
  const operationsColumns = [
    {
      Header: 'Operations',
      accessor: 'name',
      Cell: ({ row }: any) => {
        return (
          <h1
            className="hyperlink"
            onClick={() => {
              row?.original?._id &&
                navigate(`/settings/configure/operationalprocesses/editdetails/${row?.original?._id}`)
            }}
          >
            {row.original?.name ?? '-'}
          </h1>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ value }: any) => {
        return <p>{value ? value : '-'}</p>
      },
      disableSortBy: true,
    },
  ]

  const machineDetailsColumns = [
    {
      Header: 'Machine',
      accessor: 'name',
      Cell: ({ row }: any) => {
        return (
          <h1
            className="hyperlink"
            onClick={() => {
              row?.original?._id && navigate(`/maintenance/machines/${row?.original?._id}/view`)
            }}
          >
            {row.original?.name ?? '-'}
          </h1>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'Machine Class',
      accessor: 'machineClass',
      Cell: ({ row }: any) => {
        return <h1>{row.original?.name ?? '-'}</h1>
      },
      disableSortBy: true,
    },
    {
      Header: 'Model Number',
      accessor: 'modelNumber',
      Cell: ({ row }: any) => {
        return <h1>{row.original?.machineClass?.models?.modelNumber ?? '-'}</h1>
      },
      disableSortBy: true,
    },
    {
      Header: 'Serial Number',
      accessor: 'serialNumber',
      Cell: ({ row }: any) => {
        return <h1>{row.original?.serialNumber ?? '-'}</h1>
      },
      disableSortBy: true,
    },
  ]

  const shiftColumns = [
    {
      Header: 'Shift Policies',
      accessor: 'policyName',
      disableSortBy: true,
    },
    {
      Header: 'Shifts',
      accessor: 'shifts',
      Cell: ({ value }: any) => {
        // Map through the shifts and wrap each shiftName with Tooltip
        const operations = value?.map((item: any) => (
          <Tooltip
            key={item?.id}
            content={
              <div>
                {`${formatUnixTimeWithoutSecond(item?.startTime / 1000)} - ${formatUnixTimeWithoutSecond(item?.endTime / 1000)}`}
              </div>
            }
            position="right"
          >
            <span className="cursor-pointer">{item?.shiftName}</span>
          </Tooltip>
        ))

        return <Tags data={operations} color={'#780078'} bgColor={'#FFE3FF'} />
      },
      disableSortBy: true,
    },
  ]
  const params = useParams()

  const plantId = useSelector(selectSelectedItem)
  const [deletePopUp, setDeletePopUp] = useState<any>({ isPopUpOpen: false, workCenter: '' })
  const { data: workCenter, isLoading: workCenterLoading } = useGetWorkCenterByIdQuery(
    {
      plantId: plantId?.id,
      wcID: params?.id,
    },
    {
      skip: !params?.id || !plantId?.id,
    },
  )

  const [activeTab, setActiveTab] = useState(WorkCenterTab[0])
  const filteredData = (name: string) => {
    switch (name) {
      case 'Operations':
        return workCenter?.operations
      case 'Machine Details':
        return workCenter?.machines
      case 'Shift Details':
        return workCenter?.shiftPolicies
      default:
        return []
    }
  }

  const columns = (name: string) => {
    switch (name) {
      case 'Operations':
        return operationsColumns
      case 'Machine Details':
        return machineDetailsColumns
      case 'Shift Details':
        return shiftColumns
      default:
        return []
    }
  }
  const columnsArray = columns(activeTab.name)
  const WorkCenterData = filteredData(activeTab.name)
    const handleupdateWorkOrder = () => {
navigate(`/settings/configure/work-centers/${workCenter?._id}/edit/basic-details`)
  }

  return (
    <MotionDiv>
      <div className=" w-full">
        {!workCenterLoading ? (
          <WhiteCard className="h-screen mt-3  flex flex-col gap-6">
            <div className="flex justify-between">
              <h5 className="h5">{workCenter?.name}</h5>
              <div className="flex gap-4">
                <Button
                  color="error"
                  onClick={() => {
                    workCenter && setDeletePopUp({ isPopUpOpen: true, workCenter: workCenter })
                  }}
                >
                  Delete Work Center
                </Button>
                <Button color="primary" onClick={() => {handleupdateWorkOrder()}}>
                  Edit Work Center
                </Button>
              </div>
            </div>

            <div className="grid grid-cols-[150px,1fr] gap-4 text-xs">
              <span className="text-gray-500">Work Center ID</span>
              <span>{workCenter?.workCenterID ?? '-'}</span>
              <span className="text-gray-500">Work Center name</span>
              <span>{workCenter?.name ?? '-'}</span>
              <span className="text-gray-500">Description</span>
              <span>{workCenter?.description ?? '-'}</span>
            </div>
            <div className="mb-1 flex flex-wrap gap-3">
              {WorkCenterTab.map((tab: any) => (
                <ProductTab
                  key={tab._id}
                  onClick={() => {
                    setActiveTab(tab)
                  }}
                  tabText={tab.name}
                  isActive={activeTab.name === tab.name}
                />
              ))}
            </div>
            <PaginatedTable
              columns={columnsArray}
              rows={WorkCenterData}
              emptyMessage={
                activeTab.name === 'Operations'
                  ? 'No Operations are added. Please add an Operation'
                  : activeTab.name === 'Machine Details'
                    ? 'No Machine Details are added. Please add a Machine Details'
                    : 'No Shift are added. Please add a Shift'
              }
              hidePagination={true}
            />

            <Popup
              isOpen={deletePopUp.isPopUpOpen}
              title="Delete Work Center"
              onClose={() => {
                setDeletePopUp({ isPopUpOpen: false, workCenter: '' })
              }}
            >
              <DeleteWorkCenter
                closePopUp={() => setDeletePopUp({ isPopUpOpen: false, workCenter: '' })}
                workCenter={deletePopUp.workCenter}
              />
            </Popup>
          </WhiteCard>
        ) : (
          <FullPageLoader />
        )}
      </div>
    </MotionDiv>
  )
}

export default ViewWorkCenter
