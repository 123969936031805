import WhiteCard from 'libs/card/WhiteCard'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { getAuthTokenAndRole } from 'utils/CheckAuth'
import BasicDetails from './BasicDetails'
import ImpactedProductionPlans from './ImpactedProductionPlans'
import SelectedMaintenanceRequest from './SelectedMaintenanceRequest'
import useTabTitle from 'libs/hooks/useTabTitle'
import { useGetMaintenanceOrderByIdQuery } from '../maintenance-order-endpoints/order.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'

const routes = [
  {
    label: 'Basic Details',
    route: 'basic-details',
  },
  {
    label: 'Selected Maintenance Requests',
    route: 'maintenance-requests',
  },
  {
    label: 'Production Plans',
    route: 'production-plans',
  },
]

const ViewMaintenanceOrder = () => {
  const navigate = useNavigate()
  const params = useParams()
  const plantId = useSelector(selectSelectedItem)

  const { data: maintenanceOrder } = useGetMaintenanceOrderByIdQuery(
    {
      plantId: plantId?.id,
      moID: params?.id,
    },
    {
      skip: !params?.id || !plantId?.id,
    },
  )

  useTabTitle('View Maintenance Order')
  const { role } = getAuthTokenAndRole()

  return (
    <div className="flex flex-col gap-2">
      <div>
        <h2 className="h4-bold-black">{maintenanceOrder?.orderID}</h2>
      </div>
      <WhiteCard>
        <div className="flex min-h-[70vh] flex-row">
          <div className="flex w-1/4 flex-col gap-2 border-r-2 p-4">
            {routes.map((route) => {
              const isSelected = params['*'] === route.route
              // not showing configure to the operator
              if (role == 'operator' && route.route == 'basic-details') {
                return null
              }
              return (
                <div key={route.route}>
                  <p
                    className={`cursor-pointer rounded-lg  ${isSelected ? 'bg-brand-primary-200 p-md-regular-500' : ' p-sm-regular'}  px-3 py-1 hover:bg-gray-100`}
                    onClick={() => navigate(`./${route.route}`)}
                  >
                    {route.label}
                  </p>
                </div>
              )
            })}
          </div>
          <div className="flex h-full w-full flex-col gap-2 p-4">
            <Routes>
              {/* Routes for NEW WIZARD */}
              <Route path="*" element={<Navigate replace to={role == 'operator' ? 'appearance' : 'basic-details'} />} />
              {role != 'operator' && <Route path="basic-details" element={<BasicDetails />} />}
              <Route path="maintenance-requests" element={<SelectedMaintenanceRequest />} />
              <Route path="production-plans" element={<ImpactedProductionPlans />} />
            </Routes>
          </div>
        </div>
      </WhiteCard>
    </div>
  )
}

export default ViewMaintenanceOrder
