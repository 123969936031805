import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import FullPageLoader from 'libs/loader/FullPageLoader'
import EditableTable from 'libs/table/EditableTable'
import { useGetShiftPolicyQuery } from 'pages/shift-management/shift-mangement-endpoints/shiftManagement.endpoints'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'

const filters = {
  page_no: 1,
  page_size: 1000,
  sortDir: 'DESC',
}

const ShiftDetails = ({ formik, setPage, loading }: any) => {
  const [shiftIndex, setShiftIndex] = useState(0)
  const { id } = useParams()
  const plant = useSelector(selectSelectedItem)
  const [errorShifts, setErrorShifts] = useState(false)
  const productLoading = false
  const navigate = useNavigate()
  const { data: shiftPolicyList, isLoading: isShiftDataLoading } = useGetShiftPolicyQuery(
    {
      plantId: plant?.id,
      filters: filters,
    },
    {
      skip: !plant?.id,
    },
  )

  useEffect(() => {
    setPage(3)
  }, [])

  const shiftPolicyOptions =
    shiftPolicyList?.data.map((item: any) => ({
      label: item.policyName,
      value: item,
    })) || []

  const newShiftRow = {
    shiftPolicy: null,
    shift: null,
  }
  const shiftsFormik = useFormik<any>({
    initialValues: {
      shifts: [newShiftRow],
    },
    onSubmit: (values) => {  
          
      const hasNullOrUndefinedShift = values?.shifts?.some(
        (item:any) => item.shift == null 
      );
      if (values?.shifts == 0 || hasNullOrUndefinedShift) {
        setErrorShifts(true)
      } else {
        formik.setFieldValue('shifts', values?.shifts)
        formik.handleSubmit()
      }
    },
  })

  const shift = shiftsFormik?.values?.shifts[shiftIndex]?.shiftPolicy?.shifts

  const shiftsOptions = shift?.map((item: any) => ({
    label: item.shiftName || '',
    value: item || '',
  }))

  // when shift policy
  useEffect(() => {
    shiftsFormik.values.shifts.forEach((shift: any, index: number) => {
      if (shift?.shiftPolicy == '') {
        shiftsFormik.setFieldValue(`shifts.${index}.shift`, null)
      }
    })
  }, [shiftsFormik.values.shifts])

  const shiftColumns = [
    {
      title: 'Shift Policy',
      flex: 2,
      content: {
        main: true,
        uniquePropertyInOption: '_id',
        options: shiftPolicyOptions,
        name: 'shiftPolicy',
        placeholder: 'Select Shift Policy',
        type: 'select',
      },
    },
    {
      title: 'Shifts',
      flex: 2,
      content: {
        main: true,
        uniquePropertyInOption: 'id',
        options: shiftsOptions,
        name: 'shift',
        placeholder: 'Select Shift Policy',
        type: 'multiselect',
      },
    },
  ]

  useEffect(() => {
    shiftsFormik.setFieldValue('shifts', formik.values.shifts)
  }, [formik.values.shifts])

  useEffect(() => {
    if (shiftsFormik?.values?.shifts[0]?.shiftPolicy != null) {
      setErrorShifts(false)
    }
  }, [shiftsFormik?.values?.shifts])

  return (
    <>
      {isShiftDataLoading ? (
        <div className="relative mx-auto flex items-center">
          <FullPageLoader />
        </div>
      ) : (
        <div className="flex min-h-[70vh] w-full flex-col px-2">
          <div>
            <div className="flex">
              <h5 className="h5-1">{'Shift Details'}</h5>
              <span data-testid="asterisk" className={'text-red'}>
                *
              </span>
            </div>

            <p className="text-sm text-primary-gray-4">
              {id
                ? 'Edit the existing shift details to make necessary changes and updates.'
                : 'Define shift Policy and shifts for the work center.'}
            </p>
          </div>
          <div className="py-6">
            <EditableTable
              formik={shiftsFormik as any}
              onSelect={(_, index: number) => {
                setShiftIndex(index)
                shiftsFormik.setFieldValue(`shifts.${index}.shift`, null)
              }}
              loading={productLoading}
              columns={shiftColumns}
              emptyMessage="+ Click on the Add Shift button to add a new Shift"
              newRow={newShiftRow}
              name="shifts"
              addButtonTitle="Add Shift"
              error={errorShifts}
              errorMessage="Error: Shift details are missing for adding new work center. Please select Shift details to proceed."
            />
          </div>
          <div className="mt-auto flex justify-between gap-2">
            <div className="flex gap-3">
              <Button type="button" onClick={() => navigate('/settings/configure/work-centers')}>
                Cancel
              </Button>
            </div>
            <div className="flex gap-3">
              <Button
                type="submit"
                onClick={() => {
                  if (id) {
                    navigate(`/settings/configure/work-centers/${id}/edit/machine-details`)
                  } else {
                    navigate(`/settings/configure/work-centers/add/machine-details`)
                  }
                }}
              >
                Previous
              </Button>
              <Button
                color="success"
                type="submit"
                loading={loading}
                onClick={() => {
                  shiftsFormik.handleSubmit()
                }}
              >
                {id ? 'Save' : 'Add'}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ShiftDetails
