import { useFormik } from 'formik'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { t } from 'i18next'
import { useEffect, useMemo } from 'react'
import {
  useGetSalesOrderByIDQuery,
  usePostSalesOrderMutation,
  usePutSalesOrderMutation,
} from 'pages/order/sales-order/sales-order-endpoints/salesOrders.endpoints'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { toast } from 'libs/toast'
import * as Yup from 'yup'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { useGetCustomersQuery } from 'pages/contacts/customer-management/customer-endpoints/customer.endpoints'
import EditableTable from 'libs/table/EditableTable'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'

type Props = {
  close: () => void
  salesOrderId: string | null
  manufactureOrderFormik: any
  data: any
}

const QuickAddEdit = ({ close, salesOrderId, manufactureOrderFormik,data }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const plantId = useSelector(selectSelectedItem)
  const [updateSalesOrder, { isLoading: updateSalesOrderLoading }] = usePutSalesOrderMutation()
  const [addSalesOrder, { isLoading: addSalesOrderLoading }] = usePostSalesOrderMutation()
  const { data: customers } = useGetCustomersQuery(
    {
      plantId: plantId?.id,
    },
    { skip: !plantId?.id },
  )

  const { data: salesOrder, isFetching: isSalesOrderFetching } = useGetSalesOrderByIDQuery(
    {
      plant_id: plantId?.id,
      soID: salesOrderId,
    },
    {
      skip: !plantId?.id || !salesOrderId,
    },
  )
  const productfilters = {
    page_no: 1,
    page_size: 1000,
    sortBy: 'updated_on',
    sortDir: 'DESC',
    searchText: '',
  }

  const { data: products, isLoading: productLoading } = useGetProductQuery(
    {
      plantId: plantId?.id,
      filters: productfilters,
    },
    {
      skip: !plantId?.id,
    },
  )
  const customerDataOptions = customers?.data?.map((customer: any) => ({
    _id: customer._id,
    label: customer.name,
    sublabel: customer.primaryContactNumber,
    value: customer?._id,
  }))

  const formik = useFormik({
    initialValues: {
      salesOrderID: '',
      status: '',
      customerID: '',
      deliveryDate: null,
      description: '',
    },
    validationSchema: Yup.object({
      salesOrderID: Yup.string().required('Sales Order ID is required'),
      status: Yup.string().required('Status is required'),
      customerID: Yup.string().required('Customer Name is required'),
      deliveryDate: Yup.string().required('Expected Delivery Date is required'),
    }),
    onSubmit: (values) => {
      const product =
        productsFormik?.values?.products
          ?.filter((product: any) => product?.product != null)
          .map((product: any) => ({
            product_id: product?.product?._id,
            quantity: product?.quantity,
          })) || []
      const payload = {
        salesOrderID: values.salesOrderID,
        status: values.status,
        customerID: values.customerID,
        deliveryDate: values.deliveryDate,
        description: values.description,
        products: product,
      }
      if (salesOrder == null) {
        addSalesOrder({ plant_id: plantId?.id, data: payload })
          .unwrap()
          .then((res) => {
            const transformedData: any = [
              {
                salesOrder: {
                  _id: res.id,
                  customerID: res.customerID,
                  deliveryDate: res.deliveryDate,
                  products: res.products.map((product: any) => ({
                    product_id: product._id,
                    quantity: product.quantity,
                  })),
                  salesOrderID: res.salesOrderID,
                  status: res.status,
                },
              },
            ]
            
            const existingSalesOrders = data?.flat() || []
            const updatedSalesOrders = [...existingSalesOrders, transformedData]
            // once the quick add is done, we need to update the new editable table
            manufactureOrderFormik.setFieldValue('salesOrders', updatedSalesOrders.flat())

            toast.success('Sales order - ' + payload.salesOrderID + ' created successfully')
            close()
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong while creating the sales order')
          })
      }
      updateSalesOrder({ plant_id: plantId.id, salesOrderId: salesOrder._id, data: payload })
        .unwrap()
        .then((res) => {
          const transformedData: any = [
            {
              salesOrder: {
                _id: res._id,
                customerID: res.customerID,
                deliveryDate: res.deliveryDate,
                plant_id: res.plant_id,
                products: res.products.map((product: any) => ({
                  product_id: product._id,
                  quantity: product.quantity,
                })),
                salesOrderID: res.salesOrderID,
                status: res.status,
              },
            },
          ]
          const existingSalesOrders = data?.flat() || []
          
          const updateExistingOrders = existingSalesOrders.filter((so: any) => so.salesOrder._id !== res._id);
          
          const updatedSalesOrders = [...updateExistingOrders, transformedData]

          // check the ID that mathces the sales order ID and update the existing sales order
          manufactureOrderFormik.setFieldValue('salesOrders', updatedSalesOrders.flat())

          toast.success('Sales order - ' + payload.salesOrderID + ' updated successfully')
          close()
        })
        .catch((error) => {
          toast.error(error?.data?.detail ?? 'Something went wrong while updating the sales order')
        })
    },
  })
  const verificaton = [
    {
      name: 'In progress',
      value: 'IN_PROGRESS',
      _id: '101',
    },
    {
      name: 'Not started',
      value: 'NOT_STARTED',
      _id: '102',
    },
    {
      name: 'Completed',
      value: 'COMPLETED',
      _id: '104',
    },
    {
      name: 'Cancelled',
      value: 'CANCELLED',
      _id: '105',
    },
  ]
  const verificatonOptions = verificaton.map((item) => ({
    label: item.name,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))
    const productOpitons = useMemo( ()=>
      products?.data
      ?.filter((item: any) => item.category === 'Finished Goods')
      .map((item: any) => ({
        label: item.name,
        value: item,
      })), [products?.data])
      
  const newProductRow = {
    product: null,
    quantity: 0,
    unit_of_measure: null,
  }
  const productsFormik = useFormik<any>({
    initialValues: {
      products: [newProductRow],
    },
    onSubmit: () => {},
  })

  const productColumns = [
    {
      title: 'Products',
      flex: 2,
      content: {
        main: true,
        uniquePropertyInOption: 'name',
        options: productOpitons,
        name: 'product',
        placeholder: 'Select Product',
        type: 'select',
      },
    },
    {
      title: 'Quantity',
      align: 'right',
      hideRightBorder: true,
      content: {
        placeholder: '0',
        type: 'number',
        name: 'quantity',
      },
    },

    {
      title: 'UOM',
      flex: 1,
      content: {
        placeholder: 'Unit',
        type: 'text',
        name: 'unit_of_measure',
        readOnly: true,
      },
    },
  ]

  useEffect(() => {
    if (salesOrder) {
      formik.resetForm({
        values: {
          salesOrderID: salesOrder.salesOrderID,
          status: salesOrder.status,
          customerID: salesOrder.customer?._id,
          deliveryDate: salesOrder.deliveryDate,
          description: salesOrder.description,
        },
      })
      productsFormik.resetForm({
        values: {
          products: salesOrder.products.map((product: any) => ({
            product: { _id: product?._id, name: product?.name },
            unit_of_measure: product?.unit_of_measure,
            quantity: typeof product?.quantity !== 'number' ? 0 : product?.quantity,
          })),
        },
      })
    }
  }, [salesOrder])
  return (
    <>
      {isSalesOrderFetching ? (
        <FullPageLoader />
      ) : (
        <>
          <div className="flex flex-col gap-2">
            <Field
              type="text"
              label="Sales Order ID"
              formik={formik}
              required={true}
              name="salesOrderID"
              placeholder="Enter Sales Order ID"
            />
            <Field
              type="select"
              label="Customer Name"
              formik={formik}
              required={true}
              name="customerID"
              placeholder="Enter Customer Name"
              options={customerDataOptions}
              defaultValue={customerDataOptions?.find((option) => option?._id === formik?.values?.customerID)}
            />
            <Field
              type="select"
              label="Status"
              formik={formik}
              required={true}
              name="status"
              defaultValue={verificatonOptions.find((option) => option?.value == formik?.values?.status)}
              options={verificatonOptions}
              placeholder="Enter Status"
            />
            <Field type="date" required={true} label="Expected Delivery Date" formik={formik} name="deliveryDate" />
            <EditableTable
              formik={productsFormik as any}
              heading={'Products'}
              onSelect={(product: any, index: number) => {
                productsFormik.setFieldValue(`products.${index}.unit_of_measure`, product.unit_of_measure)
                productsFormik.setFieldValue(`products.${index}.quantity`, 0)
              }}
              loading={productLoading}
              columns={productColumns}
              emptyMessage="+ Click on the Add Product button to add a new product"
              newRow={newProductRow}
              name="products"
              addButtonTitle="Add Product"
            />
            <div className="mt-6 flex justify-between ">
              <div>
                {salesOrder ? (
                  <Button
                    color="primary"
                    onClick={() =>
                      navigate(`/orders/sales-order/edit/${salesOrder._id}`, { state: { from: currentPath } })
                    }
                  >
                    Edit Details
                  </Button>
                ) : (
                  <Button color="primary" onClick={() => navigate(`/orders/sales-order/newSalesOrder`)}>
                    Add Details
                  </Button>
                )}
              </div>
              <div className="flex gap-2">
                <Button onClick={close}>{t('cancel')}</Button>
                <Button
                  color="primary"
                  loading={updateSalesOrderLoading || addSalesOrderLoading}
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default QuickAddEdit
