import Info from 'assets/icons/maintenance/Info'
import TaskDetails from 'assets/icons/maintenance/TaskDetails'

import WhiteCard from 'libs/card/WhiteCard'
import { useParams } from 'react-router-dom'
import { useGetMaintenanceRequestByIDQuery } from '../maintenance-request/maintenance-request'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import FullPageLoader from 'libs/loader/FullPageLoader'
import Chip from 'libs/chip/Chip'
import { formatUnixDateMultipleWithTimeZone } from 'utils/unixConverter'

const ViewMaintenanceRequest = () => {
  const paramId = useParams()
  const plantId = useSelector(selectSelectedItem)
  const { data: maintenanceRequestData, isLoading: isMaintenanceRequestLoading } = useGetMaintenanceRequestByIDQuery(
    {
      plant_id: plantId?.id,
      mrID: paramId?.id,
    },
    {
      skip: !paramId?.id || !plantId?.id,
    },
  )

  return (
    <>
      {!isMaintenanceRequestLoading ? (
        <WhiteCard className="flex h-[75vh] flex-col ">
          <div className="flex justify-between">
            <h1 className="flex items-center gap-3 text-xl font-medium text-brand-primary-shade-3">
              {maintenanceRequestData?.requestID}
              {maintenanceRequestData?.is_order === false && <Chip size="sm" title="Requested" />}
            </h1>
          </div>
          <div>
            <div className="mb-4 mt-8 flex justify-between">
              {/* General Information */}
              <div>
                <h1 className="mb-4 flex items-center gap-2 text-lg font-bold">
                  <Info />
                  General Information
                </h1>
                <div className="flex gap-4">
                  <div className="flex flex-col gap-4">
                    <h1 className="text-xs text-gray-600">Maintenance Request ID</h1>
                    <h1 className="text-xs text-gray-600">Date Created</h1>
                  </div>
                  <div className="flex flex-col gap-4">
                    <h1 className="text-xs">{maintenanceRequestData?.requestID}</h1>
                    <h1 className="text-xs">
                      {formatUnixDateMultipleWithTimeZone(
                        maintenanceRequestData?.created_on as number,
                        plantId.timezone,
                      )}
                    </h1>
                  </div>
                </div>
              </div>
              {/* Requested By */}
              <div className="mr-6 flex gap-4">
                <div className="flex flex-col gap-2">
                  <h1 className="text-xs text-gray-600">Requested by</h1>
                  {/* <h1 className="text-xs text-gray-600">Approved by</h1> */}
                  {maintenanceRequestData?.order_history?.started_by && (
                    <h1 className="text-xs text-gray-600">Started by</h1>
                  )}
                   {maintenanceRequestData?.order_history?.created_by && (
                    <h1 className="text-xs text-gray-600">Approved by</h1>
                  )}
                  {maintenanceRequestData?.order_history?.cancelled_by && (
                    <h1 className="text-xs text-gray-600">Cancelled by</h1>
                  )}
                  {maintenanceRequestData?.rejected_by && (
                    <h1 className="text-xs text-gray-600">Rejected by</h1>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="text-xs">
                    {maintenanceRequestData?.created_by.first_name + ' ' + maintenanceRequestData?.created_by.last_name}
                  </h1>
                  {maintenanceRequestData?.order_history?.started_by && 
                  <h1 className="text-xs">
                    {maintenanceRequestData?.order_history?.started_by?.first_name}{' '}
                    {maintenanceRequestData?.order_history?.started_by?.last_name}
                  </h1>}
                  {maintenanceRequestData?.order_history?.created_by && 
                  <h1 className="text-xs">
                    {maintenanceRequestData?.order_history?.created_by?.first_name}{' '}
                    {maintenanceRequestData?.order_history?.created_by?.last_name}
                  </h1>}
                  {maintenanceRequestData?.order_history?.cancelled_by && 
                  <h1 className="text-xs">
                    {maintenanceRequestData?.order_history?.cancelled_by?.first_name}{' '}
                    {maintenanceRequestData?.order_history?.cancelled_by?.last_name}
                  </h1>}
                  {maintenanceRequestData?.rejected_by &&
                  <h1 className="text-xs">{maintenanceRequestData?.rejected_by?.first_name + ' ' + maintenanceRequestData?.rejected_by?.last_name}</h1>}
                </div>
              </div>
            </div>
          </div>

          {/* Task Details */}
          <div className="my-4">
            <h1 className="mb-4 flex items-center gap-2 text-lg font-bold">
              <TaskDetails />
              Task Details
            </h1>
            <div className="flex gap-4">
              <div className="flex flex-col gap-4">
              {maintenanceRequestData?.machine &&<h1 className="text-xs text-gray-600">Machines</h1>}
                <h1 className="text-xs text-gray-600">Reason</h1>
                <h1 className="text-xs text-gray-600">Description</h1>
              </div>
              <div className="flex flex-col gap-4">
              {maintenanceRequestData?.machine && <h1 className="text-xs">{maintenanceRequestData?.machine}</h1>}
                <h1 className="text-xs">{maintenanceRequestData?.reason}</h1>
                <h1 className="text-xs">{maintenanceRequestData?.description}</h1>
              </div>
            </div>
          </div>
        </WhiteCard>
      ) : (
        <FullPageLoader />
      )}
    </>
  )
}
export default ViewMaintenanceRequest
