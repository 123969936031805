import React from "react";
import { useState } from "react";

const Bin: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const fillColor = isHovered ? "red" : "#75838D";
  return (
    <svg
      data-testid="bin-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <mask
        id="mask0_1005_3437"
        style={{ maskType: "alpha" }}
        width="18"
        height="18"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H18V18H0z"></path>
      </mask>
      <g mask="url(#mask0_1005_3437)">
        <path
          fill={fillColor}
          d="M5.25 15.75c-.412 0-.766-.147-1.06-.44a1.445 1.445 0 01-.44-1.06V4.5a.726.726 0 01-.534-.216A.726.726 0 013 3.75c0-.212.072-.39.216-.534A.726.726 0 013.75 3h3c0-.212.072-.39.216-.534A.726.726 0 017.5 2.25h3c.213 0 .39.072.534.216A.726.726 0 0111.25 3h3c.213 0 .39.072.534.216A.726.726 0 0115 3.75c0 .212-.072.39-.216.534a.726.726 0 01-.534.216v9.75c0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44h-7.5zm7.5-11.25h-7.5v9.75h7.5V4.5zm-6 8.25h1.5V6h-1.5v6.75zm3 0h1.5V6h-1.5v6.75z"
        ></path>
      </g>
    </svg>
  );
};

export default Bin;
