import React, { useState, ReactNode } from 'react'

interface TooltipProps {
  content: ReactNode
  children: ReactNode
  position?: 'top' | 'right' | 'bottom' | 'left' | 'center'
}

const Tooltip: React.FC<TooltipProps> = ({ content, children, position = 'top' }) => {
  const [isVisible, setIsVisible] = useState(false)

  const showTooltip = () => setIsVisible(true)
  const hideTooltip = () => setIsVisible(false)

  const getPositionStyles = () => {
    switch (position) {
      case 'top':
        return { bottom: '100%', left: '50%', transform: 'translateX(-50%)' }
      case 'right':
        return { top: '50%', left: '100%', transform: 'translateY(-50%)' }
      case 'bottom':
        return { top: '100%', left: '50%', transform: 'translateX(-50%)' }
      case 'left':
        return { top: '50%', right: '100%', transform: 'translateY(-50%)' }
      case 'center':
        return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }
      default:
        return { bottom: '100%', left: '50%', transform: 'translateX(-50%)' }
    }
  }

  const getArrowStyles = () => {
    switch (position) {
      case 'top':
        return {
          bottom: '-6px',
          left: '40%',
          transform: 'translateX(-50%)',
          borderWidth: '6px 6px 0 6px',
          borderColor: 'black transparent transparent transparent',
        }
      case 'right':
        return {
          top: '50%',
          left: '-6px',
          transform: 'translateY(-50%)',
          borderWidth: '6px 6px 6px 0',
          borderColor: 'transparent black transparent transparent',
        }
      case 'bottom':
        return {
          top: '-6px',
          left: '50%',
          transform: 'translateX(-50%)',
          borderWidth: '0 6px 6px 6px',
          borderColor: 'transparent transparent black transparent',
        }
      case 'left':
        return {
          top: '50%',
          right: '-6px',
          transform: 'translateY(-50%)',
          borderWidth: '6px 0 6px 6px',
          borderColor: 'transparent transparent transparent black',
        }
      default:
        return {
          bottom: '-6px',
          left: '50%',
          transform: 'translateX(-50%)',
          borderWidth: '6px 6px 0 6px',
          borderColor: 'black transparent transparent transparent',
        }
    }
  }

  return (
    <div className="relative mt-1 inline-block px-2 py-1  " onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {isVisible && (
        <div
          className="p-xxs-regular z-999 absolute w-auto whitespace-nowrap rounded-lg bg-black p-2 text-white "
          style={getPositionStyles()}
        >
          {content}
          <span
            className="absolute"
            style={{
              ...getArrowStyles(),
              position: 'absolute',
              width: '0',
              height: '0',
              borderStyle: 'solid',
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Tooltip
