import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
// import { toast } from 'libs/toast'
import { buildUrl } from 'utils/BuildUrl'
import {
  PostTimesheetRecordParams,
  PostTimesheetRecordResponse,
  PutTimesheetRecordParams,
  GetTimesheetResponse,
  GetTimesheetParams,
  getTimesheetByIdParams,
  getTimesheetPatchParams,
  GetTimesheet,
} from './timesheetTypes'
import { toast } from 'libs/toast'

export const timesheet = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postTimesheet: builder.mutation<PostTimesheetRecordResponse, PostTimesheetRecordParams>({
      query: ({ data, plant_id }) => {
        return {
          url: `/plant/${plant_id}/timesheet`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['timesheetUpdate'],
    }),
    putTimesheet: builder.mutation<any, PutTimesheetRecordParams>({
      query: ({ plant_id, timesheetID, data }) => {
        return {
          url: `/plant/${plant_id}/timesheet/${timesheetID}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['timesheetUpdate'],
    }),
    getTimesheet: builder.query<GetTimesheetResponse, GetTimesheetParams>({
      query: ({ plantId, filters }) => {
        const URL = buildUrl(`/plant/${plantId}/timesheets`, { ...filters })
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Timesheet ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['timesheetUpdate'],
    }),

    VerifyTimesheetById: builder.mutation<string, getTimesheetPatchParams>({
      query: ({ plant_id, data }) => {
        return {
          url: `/plant/${plant_id}/timesheet/approve-many`,
          method: 'PATCH',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['timesheetUpdate'],
    }),

    getTimesheetByID: builder.query<GetTimesheet, getTimesheetByIdParams>({
      query: ({ plant_id, timesheetID }) => {
        return {
          url: `/plant/${plant_id}/timesheet/${timesheetID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Timesheet ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['timesheetUpdate'],
    }),
    deleteTimesheet: builder.mutation<string, any>({
      query: ({ plant_id, data }) => {
        return {
          url: `/plant/${plant_id}/timesheets`,
          method: 'DELETE',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['timesheetUpdate'],
    }),
    RejectTimesheetById: builder.mutation<string, getTimesheetPatchParams>({
      query: ({ plant_id, data, remark }) => {
        return {
          url: `/plant/${plant_id}/timesheet/reject-many?remark=${remark}`,
          method: 'PATCH',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['timesheetUpdate'],
    }),
    getTimesheetCount: builder.query({
      query: ({ plant_id }) => {
        return {
          url: `/plant/${plant_id}/timesheet-count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the TimeSheet ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['timesheetUpdate'],
    }),
  }),
  overrideExisting: false,
})

export const {
  usePostTimesheetMutation,
  usePutTimesheetMutation,
  useGetTimesheetQuery,
  useGetTimesheetByIDQuery,
  useVerifyTimesheetByIdMutation,
  useDeleteTimesheetMutation,
  useRejectTimesheetByIdMutation,
  useGetTimesheetCountQuery,
} = timesheet
