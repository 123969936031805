import { AnimatePresence, motion } from 'framer-motion'
import { scaleMotionProps } from 'utils/FramerProps'
import CloseIcon from 'assets/icons/popup/CloseIcon'

type PopupProps = {
  children: React.ReactNode
  onClose: () => void
  isOpen: boolean
  title: string
}

/**
 * `Popup` is a component that renders a popup with animation.
 *
 * @param {object} props - The properties that define the popup.
 * @param {boolean} props.isOpen - Determines whether the popup is open or closed.
 * @param {React.ReactNode} props.children - The content to be rendered inside the popup.
 * @param {function} props.onClose - The function to be called when the popup is closed.
 * @param {string} props.title - The title of the popup.
 * @returns {ReactElement} A panel element that animates in and out based on the `isOpen` prop.
 */

const Popup = ({ isOpen, children, onClose, title }: PopupProps) => {
  return (
    <>
      <AnimatePresence>
        <motion.div
          data-testid="popup-container"
          {...scaleMotionProps}
          className={` fixed left-0 top-[3rem] flex h-full w-full items-center justify-center background-blur-black  ${
            isOpen ? '' : 'hidden'
          }`}
        >
          <div className="z-50 w-[400px] rounded-2xl p-4 shadow-2xl background-white">
            <div className="mb-3 flex justify-between">
              <h5 className="h4 text-nowrap">{title}</h5>
              <div className="cursor-pointer">
                <span onClick={onClose}>
                  <CloseIcon />
                </span>
              </div>
            </div>
            {children}
          </div>
        </motion.div>
      </AnimatePresence>
      <div className={isOpen ? 'sideNav-overlay-dark' : 'sideNav-overlay'} />
    </>
  )
}

export default Popup
